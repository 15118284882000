<template>
    <div class="confirmOrder">
        <div class="currAddress">
            <div class="addressTitle c33 centerWidth marginAuto">
                选择收货地址
            </div>
            <div class="addressList centerWidth marginAuto flex spacebetween alignCenter " v-if="addressList.length > 0">
                <div class="addressBox  cursorP" v-show="ix < 4" @click="addressId = im.id" :class="addressId == im.id ? 'addressBoxCurr' : ''" v-for="(im,ix) in addressList" :key="ix">
                    <div v-show="im.is_default == 1" class="default flex alignCenter spacearound">默认地址</div>
                    <div class="addressBoxName flex alignCenter marginAuto c33">
                        {{im.consignee_name}}
                    </div>
                    <div class="addresstext marginAuto moreHidden">
                        {{im.provice}}{{im.city}}{{im.area}}{{im.address}}{{im.consignee_phone}}
                    </div>
                </div>
                <!-- <div v-for="val in 3" :key="val" style="width: 274px;"></div> -->
                <div class="addressBox" style="opacity:0" v-for="i in (4 - addressList.length > 0 ? 4 - addressList.length : 0)" :key="i"></div>
            </div>
            <div class="addressList" v-else>
                暂无收货地址，点击下方 新增收货地址 按钮添加收货地址
            </div>
            <div class="setAddress centerWidth flex alignCenter marginAuto spacebetween ">
                <button class="cursorP" @click="$store.commit('user/SET_ADDRESSHIDDEN', true)">新增收货地址</button>
                <div style="font-size:16px;color:#47D7E3" class="flex alignCenter cursorP" @click="moreAddressHidden = true">
                    <!-- <img src="@/assets/img/my/editAddress.png" style="width:17px;height:17px;margin-right:12px;" alt=""> -->
                    更多地址
                </div>
            </div>
        </div>
        <div class="shopList centerWidth marginAuto">
            <table cellspacing="0" style="border: 1px solid #dfdfdf;margin-top:30px;width:100%;">
                <tr style="height:46px;background:#F9FAFC">
                    <th style="text-align:left;"><span style="display:inline-block;width:87px;"></span> 商品名称</th>
                    <th style="width:145px;">单价</th>
                    <th style="width:145px;">数量</th>
                    <td style="width:145px;"></td>
                    <th style="width:145px;">总价</th>
                </tr>
                <tr style="height:136px;" v-for="(im,ix) in carList" :key="ix">
                    <td>
                        <div class="flex row alignCenter marginAuto">
                            <!-- <div class="noCurr cursorP flex spacearound alignCenter" @click="checkShop(ix)">
                                <i v-show="currShop.includes(ix)" class="el-icon-check" style="color:#F03708;font-size:15px;"></i>
                            </div> -->
                            <div class="tableImg" style="margin-left:50px;">
                                <img :src="$store.getters.Baseurl  + im.cover_map" alt="">
                            </div>
                            <div class="shopname moreHidden cursorP">{{im.name}}</div>
                        </div>
                    </td>
                    <td style="color:#F03708">{{im.integral}}积分 + ￥{{im.price}}</td>
                    <td><div class="flex alignCenter marginAuto" style="width:90px;">
                        <!-- <div @click="editNumber(im, 1)" class="addBtn flex alignCenter spacearound cursorP"><i class="el-icon-plus fontW"></i></div> -->
                        <div style="width:40px;margin:auto;">{{im.num}}</div>
                        <!-- <div @click="editNumber(im, 2)" class="addBtn flex alignCenter spacearound cursorP"><i class="el-icon-minus fontW"></i></div> -->
                    </div></td>
                    <td></td>
                    <td style="color:#F03708">{{im.integral * im.num | tofixed()}}积分 + ￥{{im.price * im.num | tofixed()}}</td>
                </tr>
                <tr style="height:108px;">
                    <td></td>
                    <td></td>
                    <td>
                        <div class="flex alignCenter spacearound flexColumn" style="height:88px;">
                            <span style="color: #333;font-size:14px;">运费</span>
                            <span style="color: #F03708;font-size:14px;" class="fontW">￥{{allDetail.freight}}</span>
                        </div>
                    </td>
                    <td><div class="flex alignCenter spacearound flexColumn" style="height:88px;">
                            <span style="color: #333;font-size:14px;">单价</span>
                            <span style="color: #F03708;font-size:14px;" class="fontW">￥{{allDetail.price}}</span>
                        </div></td>
                    <td><div class="flex alignCenter spacearound flexColumn" style="height:88px;">
                            <span style="color: #333;font-size:14px;">总价</span>
                            <span style="color: #F03708;font-size:14px;" class="fontW">￥{{allDetail.total_price ? allDetail.total_price : (`${(carList[0].integral * carList[0].num).toFixed(2)}积分 + ￥${((carList[0].price * carList[0].num) + carList[0].freight).toFixed(2)}`)}}</span>
                        </div></td>
                </tr>
            </table>
        </div>
        <div class="centerWidth marginAuto remark">
            <p style="font-size: 15px;text-align:left;" class="c33">添加订单备注</p>
            <el-input
            style="margin-top:25px;"
            type="textarea"
            resize="none"
            :maxlength='45'
            :rows="1"
            placeholder="添加备注信息，限45字"
            v-model="textarea">
            </el-input>
        </div>
        <div class="operation centerWidth marginAuto flex alignCenter spacebetween">
            <span></span>
            <div>
                <span class="c33 fontW" style="font-size:16px;">应付金额：￥{{allDetail.total_price}}</span>
                <button class="cursorP" @click="createPccarOrder">结算</button>
            </div>
        </div>

        <el-dialog
            :visible.sync="moreAddressHidden"
            width="600px"
            center>
            <div class="scrollHidden" style="height:400px;width:100%;overflow:auto;">
                <div class=" moreAddressList flex spacearound flexColumn" :class="moreAddressIndex == ix ? 'moreAddressListCurr' : ''" style="" v-for="(im,ix) in addressList" :key="ix">
                    <p class="">{{im.consignee_name}}&nbsp;{{im.consignee_phone}}&nbsp;{{im.provice}}&nbsp;{{im.city}}&nbsp;{{im.area}}&nbsp;{{im.address}}</p>
                    <span v-show="moreAddressIndex != ix" style="width:35px;" class="cursorP" @click="moreAddressIndex = ix">使用</span>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="moreAddressHidden = false">取 消</el-button>
                <el-button type="primary" @click="moreAddressListNext">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { setToken } from '@/utils/auth'
import { goods_car_detail, create_pccar_order, goods_order_pc, create_order_pc } from '@/utils/Api/shop'
export default {
    name:'confirmOrder',
    filters:{
        tofixed(val){
            return val.toFixed(2)
        }
    },
    data(){
        return{
            addressId: null,
            allDetail:{},
            carList: [],
            textarea:'',
            id_arr:'',
            moreAddressHidden: false,
            moreAddressIndex: null
        }
    },
    created(){
        this.initial(this.$route.query.ids)
    },
    methods:{
        moreAddressListNext(){
            this.addressList.splice(0,1)
            this.addressId = this.addressList[this.moreAddressIndex-1].id
            this.addressList.splice(0,0,this.addressList[this.moreAddressIndex-1])
            this.moreAddressHidden = false
        },
        async createPccarOrder(){
            let params = {
                address_id: this.addressId,
                remark: this.textarea
            }
            let res
            if(this.$route.query.ids){
                params.id_arr = this.id_arr
                res = await create_pccar_order(params)
            }else{
                params.goods_id = this.carList[0].id
                params.num = this.carList[0].num
                res = await create_order_pc(params)
            }
            if(res.order_num){
                setToken(this.allDetail.total_price, 'price')
                if(this.allDetail.isOnly){
                    goods_order_pc({order_num: res.order_num, pay_method: 3}).then(payRes=>{
                        if(payRes.data.length < 1){
                            // this.$message.success('购买成功！')
                            this.$router.push('/goodslist/paySuccess')
                        }
                    })
                    return
                }
                this.$router.replace({path: '/goodslist/ordersuccess', query: {orderNum: res.order_num, isOnly: this.allDetail.isOnly, addressId: this.addressId, s: 3}})
            }
        },
        initial(ids){
            this.id_arr = ids
            if(this.addressList.length > 0){
                this.addressId = this.addressList[0].id
                this.addressList.forEach((im,ix) => {
                    if(im.is_default == 1){
                        this.addressId = im.id
                    }
                });
            }
            if(ids){
                this.carDetail(ids)
            }else{
                this.carList = this.$store.getters.shopOrder

                this.allDetail = {
                    isOnly: this.carList[0].freight,
                    price: this.carList[0].integral + '积分 + ￥' + this.carList[0].price,
                    total_price: (this.carList[0].integral * this.carList[0].num).toFixed(2) + '积分 + ￥' + ((this.carList[0].price * this.carList[0].num) + this.carList[0].freight).toFixed(2),
                    isOnly: (this.carList[0].price * this.carList[0].num) + this.carList[0].freight == 0 ? true : false
                }
            }
        },
        async carDetail(val){
            let res = await goods_car_detail({id_arr: val})
            this.carList = res.list
            let priceNum = 0
            res.list.forEach((im,ix) => {
                priceNum += parseFloat(im.price) * parseFloat(im.num)
            })
            res.isOnly = priceNum + parseFloat(res.freight) > 0 ? false : true
            this.allDetail = res
        }
    },
    computed:{
        addressList(){
            return this.$store.getters.addressList
        },
        /* carList(){
            return this.$store.getters.accountShop
        } */
    }
}
</script>
<style lang="scss" scoped>
.moreAddressListCurr{
    background: #47D7E3!important;
    color: #FFFFFF!important;
}
.moreAddressList{
    width: 100%;
    height:60px;
    background: #eee;
    margin-bottom: 5px;
    // line-height: 30px;
    transition: all .1s;
    &:hover{
        background: #47D7E3;
        color: #FFFFFF;
    }
}
.operation{
    margin-top: 35px;
    padding-bottom: 35px;
    height: 52px;
    button{
        width: 125px;
        height: 52px;
        opacity: 1;
        background: #f03708;
        border-radius: 4px;
        font-size: 19px;
        color: #FFFFFF;
        margin-left: 50px;
    }
}
.remark{
    margin-top: 35px;
}
td{
    border-bottom: 1px solid #dfdfdf;
}
.tableImg{
    width: 101px;
    height: 101px;
    margin-left: 20px;
    margin-right: 30px;
    img{width: 100%;height: 100%;}
}
.shopList{
    margin-top: 35px;
}
.setAddress{
    margin-top: 30px;
    button{
        width: 121px;
        height: 39px;
        background: #47d7e3;
        font-size: 16px;
        color: #fff;
    }
}
.confirmOrder{
    .currAddress{
        .addressTitle{
            margin-top: 33px;
            text-align: left;
            font-size: 16px;
        }
        .addressList{
            margin-top: 25px; 
            .addressBoxCurr{
                background: url('../../../assets/img/my/currAddressBG.png')!important;
                background-size: cover !important;
            }
            .addressBox{
                .default{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 72px;
                    height: 27px;
                    opacity: 0.8;
                    background: #dbdbdb;
                    border-radius: 2px;
                    font-size: 14px;
                    color: #fff;
                }
                position: relative;
                width: 274px;
                height: 123px;
                background: url('../../../assets/img/my/AddressBG.png');
                background-size: cover;
                .addresstext{
                    margin-top: 13px;
                    width: 241px;
                    font-size: 13px;
                    color: #666666;
                    text-align: left;
                    line-height: 18px;
                }
                .addressBoxName{
                    width: 241px;
                    height: 44px;
                    border-bottom: 1px solid #dedddb;
                    font-size: 14px;
                }
            }
        }
    }
}
</style>